import localFont from 'next/font/local';
import '../styles/globals.css';

const gilroy = localFont({
  src: [
    {
      path: '../public/fonts/gilroy/Gilroy-Regular.woff',
      weight: '400',
      style: 'normal'
    },
    {
      path: '../public/fonts/gilroy/Gilroy-SemiBold.woff',
      weight: '600',
      style: 'normal'
    },
    {
      path: '../public/fonts/gilroy/Gilroy-Bold.woff',
      weight: '700',
      style: 'normal'
    }
  ],
  display: 'swap'
});

const App = ({ Component, pageProps }) => {
  return (
    <main className={gilroy.className}>
      <Component {...pageProps} />
    </main>
  );
};

export default App;
